@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* FONTS */

@font-face {
  font-family: "Calistoga";
  src: url("/src//assets/fonts/Calistoga-Regular.ttf") format("ttf");
  /* You can include more font formats here if needed */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "raleway";
  src: url("/src/assets/fonts/Raleway-Regular.ttf") format("ttf");
  /* You can include more font formats here if needed */
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Sen";
  src: url("/src//assets/fonts/Sen-VariableFont_wght.ttf") format("ttf");
  /* You can include more font formats here if needed */
  font-weight: normal;
  font-style: normal;
}
/* @font-face {
  font-family: "handitc";
  src: url("../../assets/fonts/BRADHITC.TTF") format("truetype");
  font-weight: normal;
  font-style: normal;
} */

/*= 
Variables=
*/

:root {
  /* dark shades of primary color*/
  --clr-primary-1: hsl(205, 86%, 17%);
  --clr-primary-2: hsl(205, 77%, 27%);
  --clr-primary-3: hsl(205, 72%, 37%);
  --clr-primary-4: hsl(205, 63%, 48%);
  /* primary/main color */
  --clr-primary-5: hsl(205, 78%, 60%);
  /* lighter shades of primary color */
  --clr-primary-6: hsl(205, 89%, 70%);
  --clr-primary-7: hsl(205, 90%, 76%);
  --clr-primary-8: hsl(205, 86%, 81%);
  --clr-primary-9: hsl(205, 90%, 88%);
  --clr-primary-10: hsl(205, 100%, 96%);
  /* darkest grey - used for headings */
  --clr-grey-1: hsl(209, 61%, 16%);
  --clr-grey-2: hsl(211, 39%, 23%);
  --clr-grey-3: hsl(209, 34%, 30%);
  --clr-grey-4: hsl(209, 28%, 39%);
  /* grey used for paragraphs */
  --clr-grey-5: hsl(210, 22%, 49%);
  --clr-grey-6: hsl(209, 23%, 60%);
  --clr-grey-7: hsl(211, 27%, 70%);
  --clr-grey-8: hsl(210, 31%, 80%);
  --clr-grey-9: hsl(212, 33%, 89%);
  --clr-grey-10: hsl(210, 36%, 96%);
  --clr-white: #fff;
  --clr-red-dark: hsl(360, 67%, 44%);
  --clr-red-light: hsl(360, 71%, 66%);
  --clr-green-dark: hsl(125, 67%, 44%);
  --clr-green-light: hsl(125, 71%, 66%);
  --clr-black: #222;
  --transition: all 0.45s linear;
  --spacing: 0.1rem;
  --radius: 0.25rem;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --max-width: 1170px;
  --fixed-width: 620px;
}
/*= 
Global Styles=
*/

/*  global classes */

.activeClass {
  color: #c39a4b;
  border: 1px solid #ded4c2bc;
  border-radius: 15px;
  background-color: #ded4c2bc;
}

.courrssee {
  font-family: handitc;
}

.custom-red {
  color: #000066;
}
.custom-green {
  color: #c39a4b;
}

.custom-bg-red {
  background: #000066;
}

.custom-bg-green {
  background: #c39a4b;
}

.container {
  width: 88%;
  margin: auto;
}

@media (min-width: 0px) and (max-width: 567px) {
  .container {
    width: 92% !important;
    margin: auto;
  }
}
/* section */
.section {
  width: 90vw;
  margin: 0 auto;
  max-width: var(--max-width);
}

@media screen and (min-width: 992px) {
  .section {
    width: 95vw;
  }
}

/*= 
Stripe Styles=
*/
.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  position: relative;
  z-index: 1;
}
.nav-center {
  width: 100%;
}
.nav-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-links {
  display: none;
}

/* nav media query */
@media screen and (min-width: 1200px) {
  .nav-center {
  }
  .toggle-btn {
    display: none;
  }

  .nav-links {
    display: flex;
  }
  .nav-links li {
    height: 100%;
  }
}

/* sidebar */
.sidebar-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  visibility: hidden;
  z-index: -1;
  transition: var(--transition);
  transform: scale(0);
  background: rgba(0, 0, 0, 0.5);
}

.sidebar-links {
  overflow-y: scroll;
  height: 95vh;
  padding-bottom: 20%;
  margin-top: 15%;
}

::-webkit-scrollbar {
  width: 0;
}

.sidebar-wrapper.show {
  visibility: visible;
  z-index: 1000000;
  transform: scale(1);
}
.sidebar {
  width: 90vw;
  height: 95vh;
  max-width: var(--fixed-width);
  background: var(--clr-white);
  border-radius: var(--radius);
  box-shadow: var(--dark-shadow);
  position: relative;
  padding: 2rem 1rem;
}
.close-btn {
  font-size: 2rem;
  background: transparent;
  border-color: transparent;
  color: #000066;
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
}
.sidebar article {
  margin-bottom: 2rem;
}
.sidebar-sublinks {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 0.25rem;
}
.sidebar-sublinks a {
  display: block;
  color: var(--clr-grey-1);
  text-transform: capitalize;
  display: flex;
  align-items: center;
  font-size: 12px;
  margin: 2% 0%;
}
.sidebar-sublinks svg {
  color: var(--clr-grey-5);
  margin-right: 1rem;
}

@media screen and (min-width: 1200px) {
  .sidebar-wrapper {
    display: none;
  }
}

@media (min-width: 0px) and (max-width: 575px) {
  .nav-logo {
    width: 100% !important;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .nav-logo {
    width: 90% !important;
  }
}

@media (min-width: 768px) and (max-width: 969px) {
  .nav-logo {
    width: 70% !important;
  }
}

/* links */
.submenu {
  background: var(--clr-white);
  box-shadow: var(--dark-shadow);
  position: absolute;
  top: 4rem;
  width: 18%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
  display: none;
  padding: 2rem;
  border-radius: 20px;
  transition: var(--transition);
}
.submenu::before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid var(--clr-white);
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
}

.nav-logo {
  width: 50%;
}

.submenu.show {
  display: block;
}

.submenu-center {
  gap: 0.25rem 2rem;
}
.col-2 {
  grid-template-columns: repeat(2, 1fr);
}
.col-3 {
  grid-template-columns: repeat(3, 1fr);
}
.col-4 {
  grid-template-columns: repeat(4, 1fr);
}
.submenu h4 {
  margin-bottom: 1.5rem;
  font-weight: bold;
}
.submenu-center a {
  width: 100%;
  display: block;
  color: var(--clr-grey-1);
  text-transform: capitalize;
  display: flex;
  margin-bottom: 10%;
  align-items: center;
  font-size: 15px;
}

.submenu-center svg {
  color: var(--clr-grey-5);
  margin-right: 1rem;
}

/*Hero*/
.ant-carousel {
  height: 90vh !important;
}

.hero-text {
  transform: translateY(20vh);
}

.ant-select {
  background-color: #fff !important;
  border-radius: 5px;
}

.content-1 {
  background: url("/src/assets/ga8a.jpg");
  background-size: cover;
  height: 90vh;
  background-position: 0px -125px;
  background-color: rgba(0, 0, 0, 0.5);
  background-blend-mode: overlay;
}

.content-2 {
  background: url("/src/assets/cert.jpg");
  background-size: cover;
  height: 90vh;
  background-position: 0px -70px;
  background-color: rgba(0, 0, 0, 0.6);
  background-blend-mode: overlay;
}
.content-3 {
  background: url("/src/assets/library4.jpg");
  background-size: cover;
  height: 90vh;
  background-position: 0px 0px;
  background-color: rgba(0, 0, 0, 0.5);
  background-blend-mode: overlay;
}

@media (min-width: 0px) and (max-width: 567px) {
  .hero-text {
    transform: translateY(15vh);
  }

  .ant-carousel {
    height: 75vh !important;
  }

  .content-1 {
    background-position: -380px 0px;
    height: 75vh !important;
    background-color: rgba(0, 0, 0, 0.75);
  }
  .content-2 {
    background-position: -380px -50px;
    height: 75vh !important;
    background-color: rgba(0, 0, 0, 0.75);
  }
  .content-3 {
    height: 75vh !important;
    background-position: -320px 0px;
    background-color: rgba(0, 0, 0, 0.75);
  }
}

/*Events*/

.events {
  background: url("/src/assets/eventImg.jpg");
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.3);
  background-blend-mode: overlay;
  background-attachment: fixed;
}

.calender {
  background: url("/src/assets/calender.jpg");
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.3);
  background-blend-mode: overlay;
  background-attachment: fixed;
}

/*corevalues */
.fixed-width-collapse {
  width: 100% !important;
}

@media (min-width: 0px) and (max-width: 767px) {
  .fixed-width-collapse {
    width: 100% !important;
  }
}

/*subscribe*/

.subHero {
  padding: "15% 0%";
}

@media (min-width: 0px) and (max-width: 575px) {
  .subHero {
  }
}

.subscribe {
  background: url("/src/assets/application1.jpg");
  background-attachment: fixed;
  background-color: rgba(0, 0, 0, 0.75);
  background-size: cover;
  background-position: 0px 00px;
  background-repeat: no-repeat;
  background-blend-mode: overlay;
  padding: 7% 0%;
}

/* about page */

.about-section {
  background: url("/src/assets/about.jpg");
  background-size: cover;

  padding: "15% 0%";
  background-position: 0px -100px;
  background-color: rgba(0, 0, 0, 0.75);
  background-blend-mode: overlay;
  background-attachment: fixed;
}

/* 
admissions */

.admission-section,
.AdvancedDiplomaPrograms-section {
  background: url("/src/assets/about.jpg");
  background-size: cover;
  padding: "15% 0%";
  background-position: 0px -100px;
  background-color: rgba(0, 0, 0, 0.75);
  background-blend-mode: overlay;
  background-attachment: fixed;
}

/* News section */

.news-section {
  background: url("/src/assets/application2.jpg");
  background-size: cover;

  padding: "15% 0%";
  background-position: 0px 100px;
  background-color: rgba(0, 0, 0, 0.75);
  background-blend-mode: overlay;
  background-attachment: fixed;
}

.publicInformation-section {
  background: url("/src/assets/application2.jpg");
  background-size: cover;
  padding: "15% 0%";
  background-position: 0px 100px;
  background-color: rgba(0, 0, 0, 0.75);
  background-blend-mode: overlay;
  background-attachment: fixed;
}

.contact-section {
  background: url("/src/assets/ict.jpg");
  background-size: cover;
  padding: "15% 0%";
  background-position: 0px 100px;
  background-color: rgba(0, 0, 0, 0.75);
  background-blend-mode: overlay;
  background-attachment: fixed;
}

/* Galley section */
.gallery-section {
  background: url("/src/assets/gallery.jpg");
  background-size: cover;

  padding: "15% 0%";
  background-position: 0px 0px;
  background-color: rgba(0, 0, 0, 0.75);
  background-blend-mode: overlay;
  background-attachment: fixed;
}

/* Events section */
.events-section {
  background: url("/src/assets/events.jpg");
  background-size: cover;

  padding: "15% 0%";
  background-position: 0px 150px;
  background-color: rgba(0, 0, 0, 0.55);
  background-blend-mode: overlay;
  background-attachment: fixed;
}

/* visionNdMission section */
.vissionandmission-section {
  background: url("/src/assets/mission.jpg");
  background-size: cover;

  background-position: 0px 0px;
  background-color: rgba(0, 0, 0, 0.55);
  background-blend-mode: overlay;
  background-attachment: fixed;
}

.collegesandschools-section {
  background: url("/src/assets/mission.jpg");
  background-size: cover;

  background-position: 0px 0px;
  padding: "15% 0%";
  background-position: 0px 150px;
  background-color: rgba(0, 0, 0, 0.55);
  background-blend-mode: overlay;
  background-attachment: fixed;
}

/* objective section */
.objectives-section {
  background: url("/src/assets/obj.jpg");
  background-size: cover;

  padding: "15% 0%";
  background-position: 0px -150px;
  background-color: rgba(0, 0, 0, 0.55);
  background-blend-mode: overlay;
  background-attachment: fixed;
}

/* core values section */
.corevalues-section {
  background: url("/src/assets/corevalues.jpg");
  background-size: cover;

  padding: "15% 0%";
  background-position: 0px 150px;
  background-color: rgba(0, 0, 0, 0.55);
  background-blend-mode: overlay;
  background-attachment: fixed;
}

/*collges and schools */
.collegesandcschools-section {
  background: url("/src/assets/college.JPG");
  background-size: cover;

  padding: "15% 0%";
  background-position: 0px 90px;
  background-color: rgba(0, 0, 0, 0.55);
  background-blend-mode: overlay;
  background-attachment: fixed;
}

/*academic and calender */
.academiccalender-section {
  background: url("/src/assets/about.jpg");
  background-size: cover;

  padding: "15% 0%";
  background-position: 0px -50px;
  background-color: rgba(0, 0, 0, 0.55);
  background-blend-mode: overlay;
  background-attachment: fixed;
}

/* Academic programmes */

.academicprogrammes-section {
  background: url("/src/assets/slider1.jpg");
  background-size: cover;

  padding: "15% 0%";
  background-position: 0px 100px;
  background-color: rgba(0, 0, 0, 0.55);
  background-blend-mode: overlay;
  background-attachment: fixed;
}

/* Resources section */
.resources-section {
  background: url("/src/assets/library4.jpg");
  background-size: cover;

  padding: "15% 0%";
  background-position: 0px 0px;
  background-color: rgba(0, 0, 0, 0.75);
  background-blend-mode: overlay;
  background-attachment: fixed;
}

/* Library section */
.library-section {
  background: url("/src/assets/library1.jpg");
  background-size: cover;

  padding: "15% 0%";
  background-position: 0px 100px;
  background-color: rgba(0, 0, 0, 0.55);
  background-blend-mode: overlay;
  background-attachment: fixed;
}

/* Ict section */
.ictdepartment-section {
  background: url("/src/assets/ict.jpg");
  background-size: cover;

  padding: "15% 0%";
  background-position: 0px 100px;
  background-color: rgba(0, 0, 0, 0.55);
  background-blend-mode: overlay;
  background-attachment: fixed;
}

/* Ict section */
.hallsofresidence-section {
  background: url("/src/assets/hostels.jpg");
  background-size: cover;

  padding: "15% 0%";
  background-position: 0px 0px;
  background-color: rgba(0, 0, 0, 0.65);
  background-blend-mode: overlay;
  background-attachment: fixed;
}

/* Studnet Life section */
.studentlife-section,
.sportsandrecreation-section {
  background: url("/src/assets/hostel2.jpg");
  background-size: cover;

  padding: "15% 0%";
  background-position: 0px 0px;
  background-color: rgba(0, 0, 0, 0.65);
  background-blend-mode: overlay;
  background-attachment: fixed;
}

/* Human resources section */
.foodservice-section {
  background: url("/src/assets/store1.jpg");
  background-size: cover;

  padding: "15% 0%";
  background-position: 0px 100px;
  background-color: rgba(0, 0, 0, 0.65);
  background-blend-mode: overlay;
  background-attachment: fixed;
}

/* Human resources section */
.sociallife-section {
  background: url("/src/assets/calender.jpg");
  background-size: cover;

  padding: "15% 0%";
  background-position: 0px 100px;
  background-color: rgba(0, 0, 0, 0.65);
  background-blend-mode: overlay;
  background-attachment: fixed;
}

/* Human resources section */
.humanresources-section {
  background: url("/src/assets/hr2.jpg");
  background-size: cover;

  padding: "15% 0%";
  background-position: 0px 100px;
  background-color: rgba(0, 0, 0, 0.55);
  background-blend-mode: overlay;
  background-attachment: fixed;
}

/* Student affairs section */
.studentaffairs-section {
  background: url("/src/assets/affairs.jpg");
  background-size: cover;

  padding: "15% 0%";
  background-position: 0px 100px;
  background-color: rgba(0, 0, 0, 0.75);
  background-blend-mode: overlay;
  background-attachment: fixed;
}
/* ICT section */
.ict {
  background: url("/src/assets/library2.jpg");
  background-size: cover;
  background-position: 0px 0px;

  background-attachment: fixed;
}
/* academics page */

.diploma-section {
  background: url("/src/assets/slider4.jpg");
  background-size: cover;

  padding: "15% 0%";
  background-position: 0px 0px;
  background-color: rgba(0, 0, 0, 0.75);
  background-blend-mode: overlay;
  background-attachment: fixed;
}

/*singe college*/

.CERTIFICATECOURSES-section {
  background: url("/src/assets/ict.jpg");
  background-size: cover;

  padding: "15% 0%";
  background-position: 0px 0px;
  background-color: rgba(0, 0, 0, 0.65);
  background-blend-mode: overlay;
  background-attachment: fixed;
}

.DIPLOMACOURSES-section {
  background: url("/src/assets/library4.jpg");
  background-size: cover;

  background-position: 0px -150px;
  background-color: rgba(0, 0, 0, 0.75);
  background-blend-mode: overlay;
  background-attachment: fixed;
}

.EXECUTIVETRAINING-section {
  background: url("/src/assets/cert.jpg");
  background-size: cover;

  background-position: 0px -30px;
  background-color: rgba(0, 0, 0, 0.55);
  background-blend-mode: overlay;
  background-attachment: fixed;
}

.COLLEGEOFAGRICULTURELIFEENVIRONMENTALSCIENCES-section {
  background: url("/src/assets/agric.jpg");
  background-size: cover;

  padding: "15% 0%";
  background-position: 0px 140px;
  background-color: rgba(0, 0, 0, 0.75);
  background-blend-mode: overlay;
  background-attachment: fixed;
}

.COLLEGEOFENGINEERINGSCIENCESTECHNOLOGY-section {
  background: url("/src/assets/eng.jpg");
  background-size: cover;

  padding: "15% 0%";
  background-position: 0px 140px;
  background-color: rgba(0, 0, 0, 0.75);
  background-blend-mode: overlay;
  background-attachment: fixed;
}

.DEPARTMENT-section {
  background: url("/src/assets/cert.jpg");
  background-size: cover;

  background-position: 0px 70px;
  background-color: rgba(0, 0, 0, 0.55);
  background-blend-mode: overlay;
  background-attachment: fixed;
}

.Admission-section {
  background: url("/src/assets/admission.jpg");
  background-size: cover;

  background-position: 0px 70px;
  background-color: rgba(0, 0, 0, 0.55);
  background-blend-mode: overlay;
  background-attachment: fixed;
}

.Contact-section {
  background: url("/src/assets/contact.jpg");
  background-size: cover;

  background-position: 0px 0px;
  background-color: rgba(0, 0, 0, 0.55);
  background-blend-mode: overlay;
  background-attachment: fixed;
}

.PublicInformation-section {
  background: url("/src/assets/publicInfo.jpg");
  background-size: cover;

  background-position: 0px 70px;
  background-color: rgba(0, 0, 0, 0.55);
  padding: "15% 0%";
  background-position: 0px 140px;
  background-color: rgba(0, 0, 0, 0.75);
  background-blend-mode: overlay;
  background-attachment: fixed;
}

.SCHOOL-section {
  background: url("/src/assets/about.jpg");
  background-size: cover;

  padding: "15% 0%";
  background-position: 0px 140px;
  background-color: rgba(0, 0, 0, 0.75);
  background-blend-mode: overlay;
  background-attachment: fixed;
}

/* footer */

@media (min-width: 0px) and (max-width: 575px) {
  .footer-list li a {
    font-size: 13px;
  }

  .footer-headers {
    font-size: 17px !important;
  }
}

svg {
  display: inline-block;
  vertical-align: middle;
}

.rsm-geographies path {
  fill: #000066;
}

.hand-font {
  font-family: "Bradley Hand", sans-serif;
}
.hand1 {
  font-family: "Bradley Hand", sans-serif;
}

/* ///////////// */
/* 




*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style-type: none;
}

:root {
  --primary: #6a59ff;
  --white: #ffffff;
  --bg: #f5f5f5;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

@media (min-width: 1440px) {
  html {
    zoom: 1.5;
  }
}

@media (min-width: 2560px) {
  html {
    zoom: 1.7;
  }
}

@media (min-width: 3860px) {
  html {
    zoom: 2.5;
  }
}

::-webkit-scrollbar {
  width: 1.3rem;
}

::-webkit-scrollbar-thumb {
  border-radius: 1rem;
  background: #797979;
  transition: all 0.5s ease-in-out;
}

::-webkit-scrollbar-thumb:hover {
  background: #222224;
}

::-webkit-scrollbar-track {
  background: #f9f9f9;
}

body {
  font-size: 1.6rem;
  background: var(--bg);
}

.container {
  max-width: 124rem;
  padding: 4rem 1rem;
  margin: 0 auto;
}

.heading {
  padding: 1rem 0;
  font-size: 3.5rem;
  text-align: center;
}

.swiper_container {
  height: 52rem;
  padding: 2rem 0;
  position: relative;
}

.swiper-slide {
  width: 37rem;
  height: 42rem;
  position: relative;
}

@media (max-width: 500px) {
  .swiper_container {
    height: 47rem;
  }
  .swiper-slide {
    width: 28rem !important;
    height: 36rem !important;
  }
  .swiper-slide img {
    width: 28rem !important;
    height: 36rem !important;
  }
}

.swiper-slide img {
  width: 37rem;
  height: 42rem;
  border-radius: 2rem;
  object-fit: cover;
}

.swiper-slide-shadow-left,
.swiper-slide-shadow-right {
  display: none;
}

.slider-controler {
  position: relative;
  bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider-controler .swiper-button-next {
  left: 58% !important;
  transform: translateX(-58%) !important;
}

@media (max-width: 990px) {
  .slider-controler .swiper-button-next {
    left: 70% !important;
    transform: translateX(-70%) !important;
  }
}

@media (max-width: 450px) {
  .slider-controler .swiper-button-next {
    left: 80% !important;
    transform: translateX(-80%) !important;
  }
}

@media (max-width: 990px) {
  .slider-controler .swiper-button-prev {
    left: 30% !important;
    transform: translateX(-30%) !important;
  }
}

@media (max-width: 450px) {
  .slider-controler .swiper-button-prev {
    left: 20% !important;
    transform: translateX(-20%) !important;
  }
}

.slider-controler .slider-arrow {
  background: var(--white);
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  left: 42%;
  transform: translateX(-42%);
  filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}

.slider-controler .slider-arrow ion-icon {
  font-size: 2rem;
  color: #222224;
}

.slider-controler .slider-arrow::after {
  content: '';
}

.swiper-pagination {
  position: relative;
  width: 15rem !important;
  bottom: 1rem;
}

.swiper-pagination .swiper-pagination-bullet {
  filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}

.swiper-pagination .swiper-pagination-bullet-active {
  background: var(--primary);
} */
